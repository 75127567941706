import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import NavBar from "../navBar/NavBar";
import TestImage from "../../images/wp.jpg";
import logoImage from "../../images/MSP LOGO WHITE.png";
import styles from "./EventCompetition.module.css";
import * as Icon from "react-bootstrap-icons";

const EventCompetition = () => {
  const nav = useNavigate();
  return (
    <Fragment>
      <NavBar />
      <div className={styles.eventCompetitionContainer}>
        <div className={styles.backgroundEventCompetition}>
          <div className={styles.shape}></div>
          <div className={styles.shape}></div>
          <div className={styles.shape}></div>
        </div>
        {/************************************ start Video section ************************************/}
        <div
          className={styles.eventCompetitionVideo}
          // style={{ backgroundImage: "url(" + process.env.REACT_APP_FETCH_IMG_URL + value.imagePath + ")" }}
          style={{ backgroundImage: "url(" + TestImage + ")" }}
        >
          <div>
            <h1>The Competition Name</h1>
            <p>12 jun</p>
          </div>

          <Icon.PlayFill className={styles.playIcon} />
        </div>
        {/************************************ end Video section ************************************/}

        {/************************************ start competition section ************************************/}
        <div className={styles.eventCompetitions}>
          <div className={styles.line}>
            <div className={styles.subLine}></div>
            <div className={styles.logoContainer}>
              <img src={logoImage} alt="logo-image" />
            </div>
            <div className={styles.subLine}></div>
          </div>

          <div className={styles.competitionsContainer}>
            <div className={styles.competition} onClick={()=> nav("/competitionBoard")}>
              <div
                className={styles.competitionImage}
                // style={{ backgroundImage: "url(" + process.env.REACT_APP_FETCH_IMG_URL + value.imagePath + ")" }}
                style={{ backgroundImage: "url(" + TestImage + ")" }}
              ></div>
              <div className={styles.info}>
                <h1>Competition Name</h1>
                <p>
                  A short description of the competition explaining what the
                  competition is and the number of team members participating in
                  this competition.
                </p>
                <button onClick={()=> nav("/competitionBoard")}>More deailes</button>
              </div>
            </div>

            <div className={styles.competition} onClick={()=> nav("/competitionBoard")}>
              <div
                className={styles.competitionImage}
                // style={{ backgroundImage: "url(" + process.env.REACT_APP_FETCH_IMG_URL + value.imagePath + ")" }}
                style={{ backgroundImage: "url(" + TestImage + ")" }}
              ></div>
              <div className={styles.info}>
                <h1>Competition Name</h1>
                <p>
                  A short description of the competition explaining what the
                  competition is and the number of team members participating in
                  this competition.
                </p>
                <button onClick={()=> nav("/competitionBoard")}>More deailes</button>
              </div>
            </div>
          </div>
        </div>

        {/************************************ end competition section ************************************/}
      </div>
    </Fragment>
  );
};
export default EventCompetition;
