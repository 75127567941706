import React, { Fragment, useEffect, useState, Component } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ComingEvent.module.css";
import facebookIcon from "../../images/icons/facebookIcon.svg";
import linkedInIcon from "../../images/icons/linkedInIcon.svg";
import cursorIcon from "../../images/icons/cursorIcon.svg";
import ComingEventData from "../../context/ComingEventData";
import {helperFN} from "../Helper/functions/helperFunctions.js";
import axios from "axios";

export class ComingEvent extends Component {
  state = {
    event: {
      ID: "",
      Name: "",
      Cover: "",
      BriefDesc: "",
      FullDesc: "",
      Date: new Date(),
      imagePath: "",
      isVisible: false,
      IsCompleted: true,
      type: 0
    },
    error: ""
  };

  async getData(){
    /** 
     * <summary>
     *  How does it work..
     *    Try to get Coming Event Data => type = 0
     *  if there is an Error
     *    then Try to get Latest Event => type = 1
     *  if there is an Error
     *    Return Error
     * </summary>
     */

    try{
      let respones = await axios.get(process.env.REACT_APP_FETCH_COMING_EVENT)
      let rtData = respones.data['comingEvents'][0];
      rtData.type = 0;
      return [respones.status, rtData];
    }catch(error){
      try {
        let respones = await axios.get(process.env.REACT_APP_FETCH_LATEST_EVENT);
        let rtData = respones.data['event'][0];
        rtData.type = 1;
        return [respones.status, rtData];
      } catch (error) {
        return [error.status, "Somthing went wrong please try again later.."];
      }
    }
  }

  async componentWillMount(){
    let respones = await this.getData()
    if(respones[0] == 200){
      this.setState({'event': respones[1]});
    }else{
      this.setState({'error': respones[1]});
    }
  }

  goEventMoreDetailes = (id) => {
    const nav = useNavigate();
    nav("/moreDetailes/" + id);
  }
  
  goRegisterEventPage = (id) => {
    const nav = useNavigate();
    nav("/moreDetailes/" + id);
  }

  render(){
    let Event = this.state['event'];
    let Error = this.state['error'];
    
    return (
      <Fragment>
        <div className={styles.comingEventContainer}>
          <main>
            <div className={styles.logoNeon}></div>
            <div className={styles.background}>
              <div className={styles.shape}></div>
              <div className={styles.shape}></div>
              <div className={styles.shape}></div>
            </div>
            {(Error.length === 0 && Event.type === 0) && (
              <div>
                <p className={styles.headline}>Upcoming Event</p>
                <h1>{Event.Name}</h1>
                <p className={styles.brif}>
                  {Event.BriefDesc} 
                </p>
                <p className={styles.date}>{helperFN.formatDate(Event.Date)}</p>
                <div className={styles.buttons}>
                  {!Event.IsCompleted && Event.isVisible && <button type="button" onClick={()=>{this.goRegisterEventPage(Event.ID)}}>Register Now</button>}
                  {Event.isVisible && <button type="button" onClick={()=>{this.goEventMoreDetailes(Event.ID)}}>More Details</button>}
                </div>
              </div>
            )}
            
            {(Error.length === 0 && Event.type === 1) && (
              <section className={styles.latestEvent}>
                <span className={styles.err}> Seems that there is no coming event for now, Please keep up</span>
                <p className={styles.headline}>Latest Event</p>
                <h1>{Event.Name}</h1>
                <div className={styles.buttons}>
                  {Event.isVisible && <button type="button" onClick={()=>{this.goEventMoreDetailes(Event.ID)}}>More Details</button>}
                </div>
              </section>
            )}

            {Error.length != 0 && (
              <section className={styles.latestEvent}>
                <h1>{Error}</h1>
              </section>
            )}
          </main>
          <div className={styles.scroll}>
            <img src={cursorIcon} alt="scroll-icone" />
            <p>Scroll to Explore</p>
          </div>
          <div className={styles.icons}>
            <a href="https://www.facebook.com/ASUTC" target="_blank">
              <img src={facebookIcon} alt="facebook-icon" />
            </a>
            <a href="https://www.linkedin.com/company/msp-tech-club-asu-22/mycompany/" target="_blank">
              <img src={linkedInIcon} alt="linkedIn-icon" />
            </a>
          </div>
        </div>
      </Fragment>
    );  
  } 
}
