import React, { Fragment, useRef, useState } from "react";
import { useNavigate,useParams } from "react-router-dom";
import GetEventByID from "../../context/getEventByID";
import NavBar from "../navBar/NavBar";
import styles from "./MoreDetailes.module.css";
import { helperFN } from "../Helper/functions/helperFunctions";

const MoreDetailes = () => {
  var {id} = useParams();
  const nav = useNavigate();
  let [dataGetter] = GetEventByID(id);
  let dataContainer = useRef(null);

  let getEventData = async () => {
      let res = await dataGetter();
      let [status, data] = [res.status, res.data["Event Info"]]
      
      if(status === 201){
        let container = dataContainer.current;
        console.log(data)
        let newEle = `
        <header>
          <div
          class="${styles.imgContainer}"
          style="background-image: url('${process.env.REACT_APP_FETCH_IMG_URL + data.imagePath}');"
          ></div>
          <div class="${styles.overlay}">
            <div
            class="${styles.eventIcon}"
            style="background-image: url('${process.env.REACT_APP_FETCH_IMG_URL + data.imagePath}');"
            >
            </div>
            <div class="${styles.eventHeader}">
              <h2>${data.Name}</h2>
              <p>${helperFN.formatDate(data.Date)}</p>
            </div>
          </div>
      </header>
      <section class="${styles.details}">
        <div>
          ${data.FullDesc}
        </div>
      </section>
      
      ${
      (!data.IsCompleted) ?
        `
        <div style="text-align: center">
            <button id="register" class="${styles.registerBtn}">Register Now</button>
        </div>
        `
      :""
      }
        `;
        container.innerHTML = newEle;
        let btn = document.getElementById("register");
        if(btn != null){
          btn.addEventListener('click',()=>{
            navRegisterEvent(data.ID)
          })
        }
      }else{
        nav("/errorPage");
      }

      
  }

  const navRegisterEvent = (id) => {
    nav("/registerEvent/"+id);
  };

  getEventData();

  return (
    <Fragment>
      <div className={styles.moreDetailesContainer}>
        <NavBar />
        <div ref={dataContainer}>
        </div>
      </div>
    </Fragment>
  );
};
export default MoreDetailes;
