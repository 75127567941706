import React, { Fragment, useEffect, useRef, useState } from "react";
import styles from "./AllEvents.module.css";
import DashNavbar from "../Helper/DashNavbar/DashNavbar";
import * as Icon from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import OldEventsData from "../../context/OldEventsData";
import { helperFN } from "../../userComponents/Helper/functions/helperFunctions";
import DeleteEvent from "../../context/DeleteEvent";
import WorkingOp from "../Helper/WorkingOperation/WorkingOp";
import SomthingWrong from "../Helper/SomthingWrong/SomthingWrong";
const allEvents = () => {
  let [workingOp, setWorking] = useState(false);
  let [deleteEvent] = DeleteEvent();
  let data = OldEventsData()[0];
  let [size, setSize] = useState(30);
  useEffect(() => {
    let width = window.innerWidth;
    if (width <= 425) {
      setSize(25);
    }
  });

  const nav = useNavigate();
  const navigator = (e) => {
    nav(e);
  };

  const showWorkOP = () => {
    let workOP = document.querySelector(`.${styles.workOperation}`);
    workOP.classList.add(styles.ShowOP);
  };

  const showWrongOP = () => {
    let workOP = document.querySelector(`.${styles.wrongOP}`);
    workOP.classList.add(styles.ShowOP);
  };

  const hideWorkOP = () => {
    let workOP = document.querySelector(`.${styles.workOperation}`);
    workOP.classList.remove(styles.ShowOP);
  };
  const hideWrongOP = () => {
    let workOP = document.querySelector(`.${styles.wrongOP}`);
    setTimeout(() => {
      workOP.classList.remove(styles.ShowOP);
    }, 2000);
  };

  const handelDelete = async (id) => {
    showWorkOP();
    setWorking(true);
    let response = await deleteEvent(id);
    if (response == 200) {
      window.location.reload(true);
    } else {
      showWrongOP();
      hideWrongOP();
    }
    hideWorkOP();
  };

  return (
    <Fragment>
      <div className={styles.wrongOP}>
        <SomthingWrong />
      </div>
      <div className={styles.workOperation}>
        <WorkingOp />
      </div>
      <main className={styles.mainContainer}>
        <section className={styles.dashboard}>
          <div className={styles.nav}>
            <DashNavbar />
          </div>

          <div className={styles.pageContainer}>
            <div className={styles.row}>
              <h4 className={styles.headline}>allEvents</h4>

              <button
                onClick={() => {
                  navigator("/Admin/AddEvent");
                }}
              >
                <Icon.Plus size={size} className={styles.icon} />
                Add new Event
              </button>
            </div>

            <div className={styles.tableContainer}>
              {data.map((value, key) => (
                <div key={key} className={styles.event}>
                  <div className={styles.details}>
                    <h5>{value.ID + " " + value.Name}</h5>
                  </div>

                  <div>
                    <p>{helperFN.formatDate(value.Date)}</p>
                  </div>

                  <div>
                    <ul className={styles.controls}>
                      <li>
                        <button className={styles.controlToggle}>
                          <Icon.ThreeDotsVertical
                            className={styles.icon}
                            size={size}
                          />
                        </button>

                        <ul>
                          <li>
                            <button
                              onClick={() => {
                                navigator("/moreDetailes/" + value.ID);
                              }}
                            >
                              <Icon.Eye size={size} />
                            </button>
                          </li>
                          <li>
                            <button>
                              <Icon.Pencil size={size} />
                            </button>
                          </li>
                          <li>
                            <button
                              onClick={() => {
                                handelDelete(value.ID);
                              }}
                            >
                              <Icon.Trash size={size} />
                            </button>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </main>
    </Fragment>
  );
};
export default allEvents;
