import React, {useEffect, useRef, useState} from "react";
import styles from "./NavBar.module.css";
import * as Icon from "react-bootstrap-icons";
import logoImage from "../../images/MSP LOGO WHITE.png";
import { useNavigate } from "react-router";
import Loading from "../Helper/LoadingSpinner/Loading";
import { Fragment } from "react";

const NavBar = () => {
  const nav = useNavigate();

  let [userData, setUserData] = useState(JSON.parse(localStorage.getItem("user")));


  let sideNav = useRef(null)

  const goHomePage = () => {
    nav("/");
  }
  const goEventsPage = () => {
    nav("/Events");
  }
  const goAboutUs = () => {
    nav("/aboutUs");
  }
  const goAdmin = () => {
    nav("/admin");
  }
  const goDashboard = () => {
    nav("/admin/dashboard");
  }
  
  const logout = () => {
    localStorage.clear("user");
    window.location.reload(true)
  }

  const toggleSideNav = (e) => {
    let element = sideNav.current;
    element.classList.toggle(styles.hide);
  }

  return (
    <Fragment>
      <nav>
        <div className={styles.logo}>
          <img src={logoImage} alt="" onClick={goHomePage} />
        </div>
        <ul className={styles.navegation}>
          <li onClick={goHomePage}>Home</li>
          <li onClick={goEventsPage}>Events</li>
          <li onClick={goAboutUs}>About Us</li>
        </ul>
        <div className={styles.loginControls}>
          {userData == null && (
            <div>
              <button className={styles.login} onClick={goAdmin}>Login</button>
            </div>
          )}
          {userData != null && (
            <div>
              <ul>
                <li>
                  <button>{userData.name}</button>
                  <ul>
                    <li>
                      <button onClick={goDashboard}>Dashboard</button>
                    </li>
                    <li>
                      <button onClick={logout}>Logout</button>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          )}
        </div>
        <button className={styles.viewNav} onClick={toggleSideNav}>
            <Icon.List size={30} />
        </button>
        <div className={styles.sideNav + " " + styles.hide} ref={sideNav}>
            <div className={styles.overlay} onClick={toggleSideNav}></div>
            
            <div className={styles.nav}>
              <div className={styles.header}>
                <img src={logoImage} alt="" onClick={goHomePage} />
                <p>MSP Tech Club</p>
                <p>Ain Shams University</p>
                <hr/>
              </div>

              <div className={styles.links}>
                <ul>

                  <li>
                    <button onClick={goHomePage}>
                      <Icon.House className={styles.icon} size={25}/>
                      Home
                    </button>
                  </li>

                  <li>
                    <button onClick={goEventsPage}>
                      <Icon.Calendar className={styles.icon} size={25}/>
                      Events
                    </button>
                  </li>

                  <li>
                    <button onClick={goAboutUs}>
                      <Icon.Chat className={styles.icon} size={25}/>
                      About Us
                    </button>
                  </li>
                </ul>

                <ul>
                  {userData == null && (
                    <li>
                      <button className={styles.login} onClick={goAdmin}>
                        <Icon.PersonCircle className={styles.icon} size={30} />
                        Login
                      </button>
                    </li>
                  )}

                  {userData != null && (
                    <li>
                      <button onClick={goDashboard}>
                        <Icon.PersonCircle className={styles.icon} size={30} />
                        {userData.name}
                      </button>
                    </li>
                  )}

                  {userData != null && (
                    <li>
                      <button className={styles.logout} onClick={logout}>
                        <Icon.DoorOpen className={styles.icon} size={30} />
                        Logout
                      </button>
                    </li>
                  )}
                </ul>
              </div>
            </div>
        </div>
      </nav>
      <Loading />
    </Fragment>
  );
};

export default NavBar;
