import React from "react";
import { Fragment } from "react";
import NavBar from "./../navBar/NavBar";
import TestImage from "../../images/wp.jpg";
import FirstPlace from "../../images/board/firstPlace.svg";
import SecondPlace from "../../images/board/secondPlace.svg";
import ThirdPlace from "../../images/board/thirdPlace.svg";
import styles from "./CompetitionBoard.module.css";
import * as Icon from "react-bootstrap-icons";

const CompetitionBoard = () => {
  return (
    <Fragment>
      <NavBar />
      <div className={styles.competitionBoardContainer}>
        <div className={styles.boardContainer}>
          {/************************************************** start second place **************************************************/}
          <div className={styles.secondPlace}>
            <h1>
              2<span>nd</span>
            </h1>
            <div className={styles.imageContainer}>
              <div
                className={styles.image}
                // style={{ backgroundImage: "url(" + process.env.REACT_APP_FETCH_IMG_URL + value.imagePath + ")" }}
                style={{ backgroundImage: "url(" + TestImage + ")" }}
              ></div>
              <img src={SecondPlace} alt="SecondPlace-image" />
            </div>
            <h2>
              <Icon.PeopleFill /> Team Name
            </h2>
            <div className={styles.members}>
              <div>
                <Icon.PersonFill /> Jane Cooper
              </div>
              <div>
                <Icon.PersonFill /> Wade Warren
              </div>
              <div>
                <Icon.PersonFill /> Esther Howard
              </div>
            </div>
          </div>
          {/************************************************** end second place **************************************************/}

          {/************************************************** start first place **************************************************/}

          <div className={styles.firstPlace}>
            <h1>
              1<span>st</span>
            </h1>
            <div className={styles.imageContainer}>
              <div
                className={styles.image}
                // style={{ backgroundImage: "url(" + process.env.REACT_APP_FETCH_IMG_URL + value.imagePath + ")" }}
                style={{ backgroundImage: "url(" + TestImage + ")" }}
              ></div>
              <img src={FirstPlace} alt="FirstPlace-image" />
            </div>
            <h2>
              <Icon.PeopleFill /> Team Name
            </h2>
            <div className={styles.members}>
              <div>
                <Icon.PersonFill /> Jane Cooper
              </div>
              <div>
                <Icon.PersonFill /> Wade Warren
              </div>
              <div>
                <Icon.PersonFill /> Esther Howard
              </div>
            </div>
          </div>
          {/************************************************** end first place **************************************************/}

          {/************************************************** start third place **************************************************/}
          <div className={styles.thirdPlace}>
            <h1>
              3<span>rd</span>
            </h1>
            <div className={styles.imageContainer}>
              <div
                className={styles.image}
                // style={{ backgroundImage: "url(" + process.env.REACT_APP_FETCH_IMG_URL + value.imagePath + ")" }}
                style={{ backgroundImage: "url(" + TestImage + ")" }}
              ></div>
              <img src={ThirdPlace} alt="ThirdPlace-image" />
            </div>
            <h2>
              <Icon.PeopleFill /> Team Name
            </h2>
            <div className={styles.members}>
              <div>
                <Icon.PersonFill /> Jane Cooper
              </div>
              <div>
                <Icon.PersonFill /> Wade Warren
              </div>
              <div>
                <Icon.PersonFill /> Esther Howard
              </div>
            </div>
          </div>
          {/************************************************** end third place **************************************************/}
        </div>

        <div className={styles.teamsContainer}>
          <hr />

          <h3>1st</h3>
          <div
            className={styles.image}
            // style={{ backgroundImage: "url(" + process.env.REACT_APP_FETCH_IMG_URL + value.imagePath + ")" }}
            style={{ backgroundImage: "url(" + TestImage + ")" }}
          ></div>
          <div className={styles.info}>
            <p>Team Name</p>
            <p>project Name or brief description</p>
          </div>
          <button>Read More</button>
          <hr />

          <h3>2nd</h3>
          <div
            className={styles.image}
            // style={{ backgroundImage: "url(" + process.env.REACT_APP_FETCH_IMG_URL + value.imagePath + ")" }}
            style={{ backgroundImage: "url(" + TestImage + ")" }}
          ></div>
          <div className={styles.info}>
            <p>Team Name</p>
            <p>project Name or brief description</p>
          </div>
          <button>Read More</button>

          <hr />

          <h3>3rd</h3>
          <div
            className={styles.image}
            // style={{ backgroundImage: "url(" + process.env.REACT_APP_FETCH_IMG_URL + value.imagePath + ")" }}
            style={{ backgroundImage: "url(" + TestImage + ")" }}
          ></div>
          <div className={styles.info}>
            <p>Team Name</p>
            <p>project Name or brief description</p>
          </div>
          <button>Read More</button>
        </div>
      </div>
    </Fragment>
  );
};

export default CompetitionBoard;
