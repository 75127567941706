import React, { Fragment, useState, useRef } from "react";
import Styles from "./Loading.module.css";

const Loading = () => {
  const [visible, setVisible] = useState(true);

  let LoopInterval = setInterval(() => {
    if (document.readyState === "complete") {
      setTimeout(() => {
        setVisible(false);
      }, 500);
      clearInterval(LoopInterval);
    }
  }, 100);

  return (
    <Fragment>
      {visible && (
        <section className={Styles.loadingSec} id="loading">
          <span className={Styles.loader}></span>
          <p>Getting Every thing ready for you Please wait..</p>
        </section>
      )}
    </Fragment>
  );
};

export default Loading;
