import React, { Fragment, useEffect, useState } from "react";
import styles from "./AboutUs.module.css";
import DashNavbar from "../Helper/DashNavbar/DashNavbar";
import * as Icon from "react-bootstrap-icons";

const AboutUs = () => {
  return (
    <Fragment>
      <main className={styles.mainContainer}>
        <section className={styles.dashboard}>
          <div className={styles.nav}>
            <DashNavbar />
          </div>

          <div className={styles.pageContainer}>
            <h4 className={styles.headline}>About us</h4>
          </div>
        </section>
      </main>
    </Fragment>
  );
};
export default AboutUs;
