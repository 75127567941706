import React, { Fragment, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./AdminLogin.module.css";
import * as Icon from "react-bootstrap-icons";
import logoImage from "../../images/MSP LOGO WHITE.png";
import PasswordToggle from "./../../context/PasswordToggle";
import { UserAuth } from "../../context/AuthContext";
import Loading from "../../userComponents/Helper/LoadingSpinner/Loading";

const AdminLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [ShowHiddenPasswordIcon, InputPasswordType] = PasswordToggle();
  const { authLogin, loggedIn } = UserAuth();

  let [error, setError] = useState({
    email: [],
    password: [],
    other: [],
  });

  const ErrPanel = useRef(null);
  const print = useRef(null);
  const progress = useRef(null);

  useEffect(() => {
    if (loggedIn()) {
      navigate("/Admin/Dashboard");
    }
  });

  const nav = useNavigate();
  const goHomePage = () => {
    nav("/");
  };

  const startFetching = async (e) => {
    e.preventDefault();
    setError({
      email: [],
      password: [],
      other: [],
    });

    let user = {
      email: email,
      password: password,
    };

    const mailRGX = /^\w+(\-\w+)*@[a-zA-z]+\w*(\-\w+)*(\.[a-zA-z]+)+$/;

    // Validate Email
    if (user.email != "") {
      if (!mailRGX.test(user.email)) {
        error.email.push(
          "Please make sure that your email like that (Example@example.com)"
        );
      }
    } else {
      error.email.push("Email field is Required");
    }

    // Validate Password
    if (user.password.length == 0) {
      error.password.push("Password field is Required");
    }

    if (error.email.length === 0 && error.password.length === 0) {
      let reply = await authLogin(user);

      if (reply.status === 201) {
        navigate("/admin/dashboard");
      } else if (reply.status === 400) {
        error.other.push("Email or password is wrong.");
        printErrors();
      } else if (reply.status === 429) {
        error.other.push("Too many requests, please try again after an hour.");
        printErrors();
      } else {
        error.other.push(
          "Something went wrong, please try again later or contact admin."
        );
        printErrors();
      }
    } else {
      printErrors();
    }
  };

  const printErrors = () => {
    setTimeout(hideErrors, 5000);

    const parent = ErrPanel.current;
    parent.classList.add(styles.show);

    const progressEle = progress.current;
    progressEle.classList.add(`${styles.show}`);

    const element = print.current;

    element.innerHTML = "";
    if (error.email.length != 0) element.innerHTML += "<h4>Email:</h4>";
    error.email.forEach((e) => {
      let newErr = `<p>${e}</p>`;
      element.innerHTML += newErr;
    });

    if (error.password.length != 0) element.innerHTML += "<h4>Password:</h4>";
    error.password.forEach((e) => {
      let newErr = `<p>${e}</p>`;
      element.innerHTML += newErr;
    });

    error.other.forEach((e) => {
      let newErr = `<p>${e}</p>`;
      element.innerHTML += newErr;
    });
  };

  const hideErrors = () => {
    const element = ErrPanel.current;
    element.classList.remove(styles.show);

    const progressEle = progress.current;
    progressEle.classList.remove(`${styles.show}`);
  };

  return (
    <Fragment>
      <Loading />
      <div className={styles.errorBox} onClick={hideErrors} ref={ErrPanel}>
        <div ref={print}></div>
        <div className={styles.progress}>
          <div ref={progress}></div>
        </div>
      </div>

      <div className={styles.background}>
        <div className={styles.shape}></div>
        <div className={styles.shape}></div>
        <div className={styles.shape}></div>
      </div>
      <form className={styles.adminLoginForm} method="POST">
        {/* start header section  */}
        <div className={styles.headerContainer}>
          <div>
            <img src={logoImage} alt="msp_logo" className={styles.logoImage} />
            <div className={styles.communityName}>
              <p>MSP Tech Club</p>
              <p>Ain Shams University</p>
            </div>
          </div>

          <div>
            <button className={styles.home} onClick={goHomePage}>
              <Icon.HouseFill className={styles.icon} size={30} />
            </button>
          </div>
        </div>
        {/* end header section  */}

        {/* start inputs section  */}
        <div className={styles.inputsContainer}>
          <div className={`${styles.email}`}>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Email"
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
            />
            <label htmlFor="email">
              <Icon.PersonFill
                className={`${styles.userIcon} ${styles.icon}`}
              />
            </label>
          </div>

          <div className={`${styles.password}`}>
            <div className={styles.pass}>
              <input
                type={InputPasswordType}
                name=""
                id="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <label htmlFor="password">
                <Icon.LockFill
                  className={`${styles.passwordIcon} ${styles.icon}`}
                />
              </label>
            </div>
            <div className={styles.showHidden}>{ShowHiddenPasswordIcon}</div>
          </div>
        </div>
        <button
          type="submit"
          className={styles.loginBtn}
          onClick={startFetching}
        >
          Login
        </button>
        {/* end inputs section  */}
      </form>
    </Fragment>
  );
};

export default AdminLogin;
