import { useEffect, useState } from "react";
import axios from "axios";
import { UserAuth } from "./AuthContext";

const DeleteEvent = () => {
    const { logout, user } = UserAuth();
    const deleteEv = async (id) => {
        let url = process.env.REACT_APP_FETCH_IMG_URL + "admin/delete-event/" + id
        let config = {
            headers: {
                'Authorization': user.token,
                'Content-Type': 'application/json'
            }
        }

        try {
            let resp = await axios.delete(url, config);
            return resp['status'];
        } catch (err) {
            return err;
        }

    }
    return [deleteEv];
};

export default DeleteEvent;