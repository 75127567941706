import { useEffect, useState } from "react";
import axios from "axios";

const GetEventByID = (id) => {

    const dataGetter = async () => {
        try {
            let response = await axios.get(process.env.REACT_APP_FETCH_IMG_URL + "home/events/" + id)
            console.log(response);
            return response;
        } catch (error) {
            return error.response;
        }
    }
    return [dataGetter];
};

export default GetEventByID;