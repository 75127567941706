
import React, { Fragment, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import OldEventsData from "../../context/OldEventsData";
import NavBar from "../navBar/NavBar";
import styles from "./Events.module.css";
import Loading from "../Helper/LoadingSpinner/Loading";
import { UserAuth } from "../../context/AuthContext";

const Events = () => {
  const { loggedIn } = UserAuth();
  const nav = useNavigate();
  useEffect(() => {
    if (loggedIn()) {
      nav("/Admin/Events");
    }
  });
  const goComingEventMoreDetailes = (id) => {
    nav("/moreDetailes/" + id);
  };

  const containerElem = useRef(null);

  const [allEvents] = OldEventsData();

  const monthes = [
    "jun",
    "feb",
    "mar",
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sep",
    "oct",
    "nov",
    "dec",
  ];

  const formatDate = (e) => {
    let year = e.substr(0, 4);
    let month = parseInt(e.substr(5, 2), 10);
    let day = e.substr(8, 2);

    return day + " " + monthes[month - 1] + ", " + year;
  };

  const addDottsToEnd = (data, e) => {
    if (data.length > e) {
      return data.substr(0, e) + "...";
    } else {
      return data;
    }
  };

  return (
    <Fragment>
      <Loading />
      <NavBar />
      <section className={styles.oldEventsContainer} ref={containerElem}>
        {allEvents.map((value, key) => (
          <div
            key={key}
            className={styles.singleEvent}
            onClick={() => {
              goComingEventMoreDetailes(value.ID);
            }}
            style={{
              backgroundImage:
                "url(" +
                process.env.REACT_APP_FETCH_IMG_URL +
                value.imagePath +
                ")",
            }}
          >
            <div className={styles.eventData}>
              <p className={styles.eventDate}>{formatDate(value.Date)}</p>
              <div className={styles.eventNameContainer}>
                <h1 className={styles.eventName} title={value.Name}>
                  {addDottsToEnd(value.Name, 10)}
                </h1>
                <p hidden className={styles.eventName}>
                  MSP
                </p>
              </div>
              <div className={styles.lower}>
                <div className={styles.eventBriefDesc}>
                  {addDottsToEnd(value.BriefDesc, 33)}
                </div>
                <button className={styles.detailesBtn}>Read More</button>
              </div>
            </div>
          </div>
        ))}
      </section>
    </Fragment>
  );
};

export default Events;
