import React, { Fragment } from 'react'
import { Routes, Route } from "react-router-dom"
import { AuthContextProvider } from './context/AuthContext'
import Home from './userComponents/home/Home'
import AboutUs from './userComponents/aboutUs/AboutUs'
import OurCommittees from './userComponents/ourCommittees/OurCommittees'
import RegisterEvent from './userComponents/RegisterEvent/RegisterEvent';
import QuestionForm from './userComponents/questForm/questForm';
import MoreDetailes from './userComponents/eventMoreDetailes/MoreDetailes'
import Events from './userComponents/Events/Events'
import ErrorPage from './userComponents/errorPage/ErrorPage'
import UnderMaintenance from './userComponents/underMaintenance/UnderMaintenance'
import EventCompetition from './userComponents/eventCompetition/EventCompetition'
import CompetitionBoard from './userComponents/competitionBoard/CompetitionBoard'
import AdminLogin from "./adminComponents/adminLogin/AdminLogin"
import EventDetails from './adminComponents/eventDetails/EventDetails'
import Dashboard from './adminComponents/dashboard/Dashboard'
import ProtectedRoute from './adminComponents/ProtectedRoute'
import AddNewEvent from './adminComponents/addNewEvent/AddNewEvent';
import AllEvents from './adminComponents/allEvents/AllEvents'
import AdminAboutUs from "./adminComponents/AboutUs/AboutUs"

const App = () => {
  return (
    <Fragment>
      <AuthContextProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/underMaintenance" element={<UnderMaintenance />} />
          <Route path="/errorPage" element={<ErrorPage />} />
          <Route path="/registerEvent/:id" element={<RegisterEvent />} />
          <Route path="/questForm" element={<QuestionForm />} />
          <Route path="/ourCommittees" element={<OurCommittees />} />
          <Route path="/Events" element={<Events />} />
          <Route path="/moreDetailes/:id" element={<MoreDetailes />} />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/Admin" element={<AdminLogin />} />
          
        <Route path="/competitionBoard" element={<CompetitionBoard/>} />
        <Route path="/eventCompetition" element={<EventCompetition/>} />
          <Route path="/Admin/Dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
          <Route path="/Admin/AddEvent" element={<ProtectedRoute><AddNewEvent /></ProtectedRoute>} />
          <Route path="/Admin/Events" element={<ProtectedRoute><AllEvents /></ProtectedRoute>} />
          <Route path="/Admin/eventDetails" element={<ProtectedRoute><EventDetails /></ProtectedRoute>} />
          <Route path="/Admin/About-us" element={<ProtectedRoute><AdminAboutUs /></ProtectedRoute>} />
        </Routes>
      </AuthContextProvider>
    </Fragment>
  );
}

export default App;
