import React, { createContext, useContext, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  let tmpUser = JSON.parse(localStorage.getItem('user'));

  if (tmpUser != null)
    if (new Date().getDate() - new Date(tmpUser.loginDate).getDate() > 7)
      tmpUser = {};

  const [user, setUser] = useState(tmpUser);
  const nav = useNavigate();

  const logout = () => {
    localStorage.clear("user");
    nav('/');
  };

  const loggedIn = () => {
    if (localStorage.getItem('user')) {
      return true;
    }
    return false;
  }

  const getResultData = (data) => {
    localStorage.setItem("user", JSON.stringify(data));
  }

  const authLogin = async (user) => {
    try {
      const response = await axios.post(process.env.REACT_APP_FETCH_ADMIN_LOGIN, {
        email: user.email,
        password: user.password
      }, {
        headers: { "Content-Type": "application/json" }
      });
      user = response.data;
      user.loginDate = new Date();
      setUser(user);
      await getResultData(user)
      return response;
    } catch (err) {
      return err.response;
    }
  };

  return (
    <AuthContext.Provider value={{ authLogin, logout, user, loggedIn }}>
      {children}
    </AuthContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(AuthContext);
};
