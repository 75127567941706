import React, { Fragment, useEffect, useState } from "react";
import styles from "./AddNewEvent.module.css";
import DashNavbar from "../Helper/DashNavbar/DashNavbar";
import * as Icon from "react-bootstrap-icons";

const AddNewEvent = () => {
  let [img, setIMG] = useState({
    name: "",
    url: "",
    type: "",
    size: "",
    overMaxSize: false,
  });

  let [event, setEvent] = useState(null);

  let [preview, setPreview] = useState(false);

  let [size, setSize] = useState(50);

  let [breifCheck, setBreifCheck] = useState(false);

  useEffect(() => {
    let width = window.innerWidth;
    if (width <= 425) {
      setSize(30);
    }
  });

  const descChange = (e) => {
    if (breifCheck) {
      let brief = document.querySelector("#eventBreif");
      brief.value = e.target.value.substring(0, 60);
    }
  };

  const checkIMGUpload = (e) => {
    let img = e.target.value;
    if (img != "") {
      const file = Array.from(e.target.files)[0];
      let urlIMG = URL.createObjectURL(file);
      let ext = img.substr(img.length - 4, 4);
      if (ext === ".jpg" || ext === "jpeg" || ext === ".png") {
        let img = {
          name: file.name,
          url: urlIMG,
          type: file.type,
          size: "",
          overMaxSize: false,
        };

        if (file.size >= 1000000) {
          img.overMaxSize = true;
          img.size = Math.round(file.size / 1024000) + "MB";
        } else {
          img.size = Math.round(file.size / 1000) + "KB";
        }

        setPreview(true);
        setIMG(img);
      } else {
        setPreview(false);
        setIMG({
          name: "",
          url: "",
          type: "",
          size: "",
          overMaxSize: false,
        });
      }
    } else {
      setPreview(false);
      setIMG({
        name: "",
        url: "",
        type: "",
        size: "",
        overMaxSize: false,
      });
    }
  };

  const handleGatheringData = (e) => {
    e.preventDefault();
    let event = {
      name: e.target[0].value,
      briefDesc: e.target[1].value,
      date: e.target[3].value,
      fullDesc: e.target[4].value,
      image: e.target[5].files[0],
      isVisible: e.target[6].value,
      isCompleted: e.target[7].value,
    };
    setEvent(event);
  };
  return (
    <Fragment>
      <main className={styles.mainContainer}>
        <section className={styles.dashboard}>
          <div className={styles.nav}>
            <DashNavbar />
          </div>

          <div className={styles.pageContainer}>
            <h4 className={styles.headline}>Add new event</h4>
            <form onSubmit={handleGatheringData}>
              <div className={styles.dataEntry}>
                <input
                  type="text"
                  id="eventName"
                  name="eventName"
                  placeholder="Event Name"
                />
                <label htmlFor="eventName">
                  <Icon.Quote className={styles.icon} size={size} />
                </label>
              </div>

              <div className={styles.row}>
                <div className={styles.dataEntry}>
                  <input
                    type="text"
                    id="eventBreif"
                    name="eventBreif"
                    placeholder="Event Breif Details"
                  />
                  <label htmlFor="eventBreif">
                    <Icon.ThreeDots className={styles.icon} size={size} />
                  </label>
                </div>

                <div className={styles.check}>
                  <label htmlFor="useBreif" className={styles.breifTXT}>
                    Get it from Full Description
                  </label>
                  <div>
                    <input
                      type="checkbox"
                      name="useBreif"
                      id="breif"
                      onChange={() => {
                        let brief = document.querySelector("#eventBreif");
                        setBreifCheck(!breifCheck);
                        brief.disabled = !breifCheck;
                      }}
                      title="Get it from Full Description"
                    />
                    <label htmlFor="useBreif"></label>
                  </div>
                </div>
              </div>

              <div className={styles.dataEntry}>
                <input type="date" id="dateTime" name="dateTime" />
                <label htmlFor="dateTime">
                  <Icon.CalendarDate className={styles.icon} size={size} />
                </label>
              </div>

              <div className={styles.dataEntry}>
                <textarea
                  id="fullDesc"
                  name="fullDesc"
                  onChange={descChange}
                  placeholder="Full Description"
                ></textarea>

                <label htmlFor="fullDesc">
                  <Icon.BodyText className={styles.icon} size={size} />
                </label>
              </div>

              <div className={styles.photoUplad}>
                <input
                  type="file"
                  accept=".jpg, .jpeg, .png"
                  id="photo"
                  name="photo"
                  onChange={checkIMGUpload}
                />

                <div className={styles.previewer}>
                  {preview ? (
                    <div className={styles.previewData}>
                      <div>
                        <img src={img.url} alt="Preview" />
                      </div>
                      <table>
                        <tbody>
                          <tr>
                            <td>Name: </td>
                            <td>{img.name}</td>
                          </tr>
                          <tr>
                            <td>Type: </td>
                            <td>{img.type}</td>
                          </tr>
                          <tr>
                            <td>Size: </td>
                            <td
                              className={img.overMaxSize ? styles.danger : ""}
                            >
                              {img.size}
                            </td>
                          </tr>
                          {img.overMaxSize ? (
                            <tr>
                              <td
                                colSpan={2}
                                className={
                                  img.overMaxSize
                                    ? styles.warning + " " + styles.danger
                                    : ""
                                }
                              >
                                Event's picture can't be greater than 1MB
                              </td>
                            </tr>
                          ) : (
                            ""
                          )}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <label htmlFor="photo">
                      <Icon.Camera className={styles.icon} size={size + 45} />
                      <p>Drag & Drop to upload Event's photo</p>
                      <p>or just Browse It</p>
                    </label>
                  )}
                </div>
              </div>

              <div className={styles.userSellections}>
                <div className={styles.check}>
                  <label htmlFor="visible">Visible :</label>
                  <div>
                    <input type="checkbox" name="visible" id="visible" />
                    <label htmlFor="visible"></label>
                  </div>
                </div>
                <div className={styles.check}>
                  <label htmlFor="complete">Complete :</label>
                  <div>
                    <input type="checkbox" name="complete" id="complete" />
                    <label htmlFor="complete"></label>
                  </div>
                </div>
                <div className={styles.save}>
                  <button type="submit">ِAdd Event</button>
                </div>
              </div>
            </form>
          </div>
        </section>
      </main>
    </Fragment>
  );
};
export default AddNewEvent;
