import React, { Fragment, useEffect, useRef, useState } from "react";
import Styles from "./SomthingWrong.module.css";
import * as Icon from "react-bootstrap-icons";

const SomthingWrong = () => {
  return (
    <section className={Styles.loadingSec}>
      <span className={Styles.loader}></span>
      <p>Somthing went wrong please try again latter</p>
    </section>
  );
};

export default SomthingWrong;
