import React, { Fragment, useEffect, useRef } from "react";
import style from "./DashNavbar.module.css";
import Logo from "../../../images/MSP LOGO Black.png";
import * as Icon from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import sadIMG from "../../../images/admin/sadMan.svg";
import Loading from "../../../userComponents/Helper/LoadingSpinner/Loading";
import { UserAuth } from "../../../context/AuthContext";

const DashNavbar = () => {
  const nav = useNavigate();
  const { logout, user } = UserAuth();

  let logoutFRM = useRef(null);
  const ToggleNavbar = () => {
    const nav = document.querySelector("." + style.linksContainer);
    nav.classList.toggle(style.hidden);
  };

  const navigator = (e) => {
    nav(e);
  };

  const revelLogoutForm = () => {
    const elem = logoutFRM.current;
    elem.classList.toggle(style.active);
  };

  return (
    <Fragment>
      <Loading />
      <section className={style.logoutWarning} ref={logoutFRM}>
        <div onClick={revelLogoutForm} className={style.overflow}></div>
        <div className={style.logoutForm}>
          <div>
            <img src={sadIMG} alt="logout image" />
          </div>
          <div>
            <p>Do you want to log out?</p>
            <div>
              <button onClick={logout}>Logout</button>
              <button onClick={revelLogoutForm}>Cancel</button>
            </div>
          </div>
        </div>
      </section>

      <section className={style.Dnav}>
        <div className={style.logo}>
          <img src={Logo} />
          <div className={style.details}>
            <h1>MSP Tech Club</h1>
            <p>Ain Shams University</p>
          </div>
        </div>

        <div className={style.linksContainer + " " + style.hidden}>
          <div className={style.overlay} onClick={ToggleNavbar}></div>
          <div className={style.links}>
            <ul>
              <li>
                <button
                  onClick={() => {
                    navigator("/Admin/dashboard");
                  }}
                >
                  <Icon.HouseFill className={style.icon} size={40} />
                  Home
                </button>
              </li>
              <li>
                <button
                  onClick={() => {
                    navigator("/Admin/Events");
                  }}
                >
                  <Icon.Table className={style.icon} size={40} />
                  Events
                </button>
              </li>
              <li>
                <button
                  onClick={() => {
                    navigator("/Admin/About-us");
                  }}
                >
                  <Icon.PersonLinesFill className={style.icon} size={40} />
                  About Us
                </button>
              </li>
            </ul>

            <ul>
              <li>
                <button>
                  <Icon.PersonFill className={style.icon} size={40} />
                  {user.name}
                </button>
              </li>
              <li>
                <button className={style.logout} onClick={revelLogoutForm}>
                  <Icon.DoorOpenFill className={style.icon} size={40} />
                  Logout
                </button>
              </li>
            </ul>
          </div>
        </div>

        <div className={style.sideNavBTN}>
          <button onClick={ToggleNavbar}>
            <Icon.List size={40} />
          </button>
        </div>
      </section>
    </Fragment>
  );
};

export default DashNavbar;
