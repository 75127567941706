import React from 'react'
import { Navigate } from "react-router-dom";
import { UserAuth } from '../context/AuthContext';

const ProtectedRoute = ({ children }) => {
  const { loggedIn } = UserAuth();
  if (!loggedIn()) {
    return <Navigate to='/' />
  }
  return (
    children
  )
}

export default ProtectedRoute